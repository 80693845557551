import React from 'react';
import ReactDOM from 'react-dom';

import { SupportedBrowsers } from './components/blocks/SupportedBrowsers';

if (window?.navigator?.userAgent.match(/MSIE|Trident/) !== null) {
  const container = document.getElementById('supportedBrowsers');

  ReactDOM.render(<SupportedBrowsers />, container);
}
