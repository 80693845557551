import React from 'react';

import { Container, Row, Col } from '@ott/grid';
import { l10n } from '@ott/l10n';

import styles from './SupportedBrowsers.scss';

export const SupportedBrowsers = () => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col desktop={12} mobile={4} className={styles.container}>
            <div className={styles.title}>{l10n('supportBrowsers.title')}</div>
            <div>{l10n('supportBrowsers.description')}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
